export class FormRequestViewmodel {
    resellerContactName: string;
    leadContactId: string;
    leadCompanyName: string;
    userId: string;
    vendorId: number;
    leadId: number;
    feedbackNumber: number;
    questionAnswers: [];
}
