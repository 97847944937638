import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'status-cell',
    template: `
    <span *ngIf="activeBtn == '1' " class="badge badge-pill badge-success w-50 font-sm">Approved</span>
    <span *ngIf="activeBtn == '3' " class="badge badge-pill badge-warning w-50 font-sm text-white">Pending</span>
    <span *ngIf="activeBtn == '2' " class="badge badge-pill badge-danger w-50 font-sm text-white">Denied</span>
    <span *ngIf="activeBtn == '5' " class="badge badge-pill badge-dark w-50 font-sm text-white">Paused</span>
    `,
    styles: []
})
export class ActiveUserRendererComponent implements ICellRendererAngularComp {
    params: any;
    activeBtn: any;
    constructor() {

    }

    agInit(params: any): void {
        this.params = params;
        this.activeBtn = params.data.accountStatus;
    }

    refresh(): boolean {
        return false;
    }
}
