import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BaseService } from '../../shared/services/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService extends BaseService {
    baseUrl: any;
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        super();
        this.baseUrl = baseUrl + "api/";
    }
    // Values getting in parent object
    post(requestBody, apiEndPoint: string): Observable<any> {
        return this.http.post(`${this.baseUrl}${apiEndPoint}`, requestBody).pipe(map((data: any) => {
            return data ? data : [];
        }));
    }

    // Values getting in return object
    postData(requestBody, apiEndPoint: string): Observable<any> {
        return this.http.post(`${this.baseUrl}${apiEndPoint}`, requestBody).pipe(map((data: any) => {
            return data && data.returnObject ? data.returnObject : [];
        }));
    }

    get(apiEndPoint): Observable<any> {
        return this.http.get(`${this.baseUrl}${apiEndPoint}`).pipe(map((data: any) => {
            return data && data.returnObject ? data.returnObject : [];
        }));
    }

    getData(apiEndPoint): Observable<any> {
        return this.http.get(`${this.baseUrl}${apiEndPoint}`).pipe(map((data: any) => {
            return data ? data : [];
        }));
    }
}
