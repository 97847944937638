import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'cell-link',
  template: `<a class="grid-cell-link pointer-link cell-link" title="{{params.value}}" (click)="onClick($event)"><u>{{ params.value }}</u></a>`,
  styles: [`
  `]
})
export class LeadLinkRendererComponent implements ICellRendererAngularComp {

  public params: any;
  public onCellClick = new EventEmitter<any>();

  constructor(private route: Router) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onClick(event: any) {
    if (this.params.data.userECId) {
      this.route.navigate(['/admin/account-information', this.params.data.leadIdRedirect, this.params.data.userECId]);
    }
    else {
      this.route.navigate(['/admin/account-information', this.params.data.leadIdRedirect]);
    }
  }
}
