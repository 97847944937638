import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor() { }

  showUserDetailsAnalyticsCharts(): boolean {
    return environment.showUserDetailsAnalyticsCharts;
  }

  showPlatformManagementChart(): boolean {
    return environment.showPlatformManagementChart;
  }

  showCampaignDetailsAnalyticsCharts(): boolean {
    return environment.showCampaignDetailsAnalyticsCharts;
  }
  disableFishtankApp(): boolean {
    return environment.disableFishtankApp;
  }
}
