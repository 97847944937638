import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class UtilityService {

    constructor(private router: Router) { }

    unsubscribeSubscriptions(subscriptions: Subscription[]) {
        subscriptions.forEach(subscription => {
            if (subscription) {
                subscription.unsubscribe();
            }
        })
    }

    navigate(path: string) {
        this.router.navigate([path]);
    }
}
