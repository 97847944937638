export const environment = {
  name: 'debug',
  disableTelemetry: true,
  instrumentationKey: 'NGRjN2I2MDctMzEwYy00ZjNjLWFlMzQtZWZjMWI5ZTc1NDZj',
  applicationType: 'FrontEnd',
  customerOnboardingUrl: 'https://register.cstenet.com',
  forgotPasswordUrl: 'https://account.cstenet.com/forgotpassword',
  showUserDetailsAnalyticsCharts: true,
  showPlatformManagementChart: false,
  showCampaignDetailsAnalyticsCharts: true,
  disableFishtankApp: true
};
