import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { AuthRedirectComponent } from './shared/components/login/auth-redirect.component';
import { HomePageComponent } from './shared/components/home-page/home-page.component';
import { ErrorComponent } from './shared/components/error-page/error.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    //    pathMatch: 'full',
    data: { title: 'FishTank | Home' }
  },
  {
    path: 'user',
    loadChildren: () => import('../app/user-dashboard/user-dashboard.module').then(user => user.UserDashboardModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/admin-dashboard/admin-dashboard.module').then(admin => admin.AdminDashboardModule)
  },
  {
    path: 'admin/login',
    component: AuthRedirectComponent,
    data: { title: 'FishTank | Admin Login' }
  },
  {
    path: 'user/login',
    component: AuthRedirectComponent,
    data: { title: 'FishTank | User Login' }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'FishTank | Login' }
  },
  {
    path: 'logout',
    component: AuthRedirectComponent,
    data: { title: 'FishTank | Logout' }
  },
  {
    path: 'context',
    component: AuthRedirectComponent,
    data: { title: 'FishTank | Login Context' }
  },
  { path: '**', component: ErrorComponent, data: { title: 'FishTank | Error' } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
