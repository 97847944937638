import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-vendor-popup',
  templateUrl: './vendor-popup.component.html',
  styleUrls: ['./vendor-popup.component.scss']
})
export class VendorPopupComponent implements OnInit {
  @Output() hideLegend: EventEmitter<boolean> = new EventEmitter(false);

  @Input() selectedVendorName: string;
  @Input() accountStatus: number;
  constructor() { }

  ngOnInit() { }

  hideComponent() {
    this.hideLegend.emit(true);
  }

}
