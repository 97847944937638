import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
    selector: 'status-review',
    template: `<span (click)="onClick($event)" *ngIf ="changeRequest"> <i class="fa fa-id-card custom-card font-xl"></i></span>`,
    styles: [`
    .custom-card {
        font-size:16px;
        text-align: center;
        cursor: pointer;
        color: #F7B500;
    }
  `]
})
export class ReviewUserRendererComponent implements ICellRendererAngularComp {
    params: any;
    changeRequest: boolean;
    constructor(private route: Router) {

    }

    agInit(params: any): void {
        this.params = params;
        this.changeRequest = params.data.hasRequestedChange;
    }

    refresh(): boolean {
        return false;
    }
    onClick(event: any) {
        this.route.navigate(['admin/user-details', this.params.data.userId]);
    }
}