import { Observable, Subject } from 'rxjs';

export interface VendorDetail {
  id: string;
  value: string;
  isOptedIn: boolean;
  isActive: boolean;
  code: string;
  name: string;
  activeClass?: string;
}

export interface CampaignInformation {
  id: number;
  isOptedIn: boolean;
}

export interface CampaignFeedbackSubmitRequest {
  campaignName: string;
  campaignCRMId: string;
  vendor: string;
  solution: CampaignInformation[];
  targetCompanyRevenue: CampaignInformation[];
  targetCompanySize: CampaignInformation[];
  targetIndustry: CampaignInformation[];
  projectITBudgetMin: string;
  leadGoal: string;
  endDate: any;
  resellerCompanyAccountNumber: {};
  campaignNote: string;
}

export interface ProfileFeedbackSubmitRequest {  
  productLine: VendorDetail[];
  salesTerritory: VendorDetail[];
  targetCompanySize: VendorDetail[];
  targetCompanyRevenue: VendorDetail[];
  targetIndustry: VendorDetail[];
}

export interface LeadManagementDetailsChart {
  name: string;
  series: LeadManagementChart[];
}

export interface PlatformManagementDetails {
  name: string;
  series: PlatformManagementChart[];
}

export interface LeadManagementChart {
  name: string;
  value: number;
}

export interface TotalRevenueChart {
  name: string;
  value: number;
}

export interface PlatformManagementBarChart {
  name: string;
  value: number;
}

export interface PlatformManagementChart {
  name: string;
  value: number;
}

export interface totalLeadOverviewDetail {
  name:string;
  series:totalLeadOverviewChart[];
}

export interface totalLeadOverviewChart {
  name:string;
  value:number
}

export interface AdminDetailsSubmitRequest {
  userId: string;
  vendorId: number;
  isApproved: boolean;
  productLine: VendorDetail[];
  salesTerritory: VendorDetail[];
  targetCompanySize: VendorDetail[];
  targetCompanyRevenue: VendorDetail[];
  targetIndustry: VendorDetail[];
}

export interface AdminVendorDetails {
  userId: string;
  vendorId: number;
  jobTitle: string;
  tdSalesRepFullName: string;
  managerFullName: string;
  managerEmailAddress: string;
  approvalDate: string;
  learnerId: string;
  productLine: VendorDetail[];
  salesTerritory: VendorDetail[];
  targetCompanySize: VendorDetail[];
  targetCompanyRevenue: VendorDetail[];
  targetIndustry: VendorDetail[];
  isApproved: boolean;
  email: string;
  fullName: string;
  Status: any;
  userNote: string;
}

export interface CampaignDetails {
  campaignId: number;
  campaignName: string;
  campaignCRMId: string;
  thirdPartyVendor: string;
  startDate: string;
  endDate: string;
  adminECId: number;
  vendor: string;
  resellerCompanyAccountNumber: [];
}
export interface AdminDetails {
  address: any;
  customerNumber: string;
  userId: string;
  isApproved: boolean;
  isActive: boolean;
  accountStatus: AccountStatus; 
  defaultLanguage: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  managerFullName: string;
  managerEmailAddress: string;
  phoneNumber: number;
  tdSalesRepFullName: string;
  filePath: string;
  vendorCertificate: string;
  vendorCertificateName: string;
  vendorId: number;
  vendorName: string;
  learnerId: string;
  hasRequestedChange: boolean;
  companyName: string;
  approvalDate: string;
  productLineString: string;
  productLine: VendorDetail[];
  salesTerritorystring: string;
  salesTerritory: VendorDetail[];
  targetCompanySizeString: string;
  targetCompanySize: VendorDetail[];
  targetCompanyRevenueString: string;
  targetCompanyRevenue: VendorDetail[];
  targetIndustryString: string;
  targetIndustry: VendorDetail[];
  wonLeadRevenueChart:[];
}

export enum AccountStatus {
    Approved = 1,   //Active
    Denied = 2,     //Inactive
    Pending = 3
}

export interface VendorCertificate {
  fileName: string;
}

export class ButtonEvent {
  emitEvent: Subject<boolean>;
  getEvent: Observable<boolean>;
  constructor() {
    this.emitEvent = new Subject<boolean>();
    this.getEvent = this.emitEvent.asObservable();
  }
}

export class ProfileButtonActions {
  submitButton: ButtonEvent = new ButtonEvent();
  cancelButton: ButtonEvent = new ButtonEvent();
  editButton: ButtonEvent = new ButtonEvent();
  isEditEnable: ButtonEvent = new ButtonEvent();
  constructor() { }
}

export class RegistrationUserDetails {
  step1: {
    fullName: string;
    email: string;
    jobTitle: string;
    managerFullName: string;
    managerEmailAddress: string;
    tdSalesRepFullName: string;
  };
  step2: {
    vendorCertificate: any;
    learnerId: string;
    productLineString: any[];
    vendorId: any;
    vendorName: any;
  };
  step3: {
    salesTerritoryString: any[];
    targetCompanySizeString: any[];
    targetCompanyRevenueString: any[];
    targetIndustryString: any[];
  }

  constructor() {
    this.step1 = this.step2 = this.step3 = null;
  }
}
