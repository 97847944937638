import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AdminDashboardService } from 'src/app/admin-dashboard/admin-dashboard.service';

@Component({
    selector: 'lead-status',
    templateUrl: './status-renderer.component.html',
    styleUrls: ['../../../shared/components/status-legend/status-legend.component.scss'],
    providers: [AdminDashboardService]
})
export class StatusRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public leadsStatusData: any;
    public onCellClick = new EventEmitter<any>();
    successFeedback = 'Feedback Successfully Received';
    saleFeedback = 'Sale Of Product';
    recycleFeedback = 'Recycled';
    noFeedback = 'No Feedback Provided';
    registerFeedback = 'Registered Opportunity';
    clockFeedback = 'Continuing To Work On The Lead';
    bant = 'BANT Discrepancy';
    lostOpt = 'Lost Opportunity';
    wonRegistered = 'Won And Registered';
    nullFeedback = null;

    constructor(private route: Router, private adminDashboardService: AdminDashboardService) { }

    agInit(params: any): void {
        this.params = params;
        this.leadsStatusData = params.data;
    }

    refresh(): boolean {
        return false;
    }
}
