import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  @Input() contentTemplate: TemplateRef<HTMLElement>;

  constructor() {}
}