import { Injectable } from '@angular/core';
import { ActiveUserRendererComponent } from '../components/grid-components/active-user-renderer.component';
import { ReviewUserRendererComponent } from '../components/grid-components/review-user-renderer.component';
import { UserprofileRendererComponent } from '../components/grid-components/user-profile-renderer.component';

@Injectable()
export class AdminLeadsConfigurations {

    public getNewUserTableConfigurations() {

        const newUserConfig = {
            columnDefs: [
                {
                    headerName: 'Full Name',
                    field: 'fullName',
                    cellRenderer: 'userProfileRenderer'
                },
                {
                    headerName: 'Email',
                    field: 'email'
                },
                {
                    headerName: 'Manager Full Name',
                    field: 'managerFullName'
                },
                { headerName: 'Sales Rep. Full Name', field: 'tdSalesRepFullName' },
                { headerName: 'Job Title', field: 'jobTitle' },
                { headerName: 'Vendor', field: 'vendorName' },
                { headerName: 'Vendor Certification', field: 'vendorCertificateName' },
                { headerName: 'Vendor Id', field: 'learnerId' },
                {
                    headerName: 'Status',
                    field: 'stauts',
                    cellRenderer: 'activeUserRenderer'
                }
            ],
            frameworkComponents: {
                activeUserRenderer: ActiveUserRendererComponent,
                userProfileRenderer: UserprofileRendererComponent
            }
        };

        return newUserConfig;
    }

    public getActiveUserTableConfigurations() {

        const activeUserConfig = {
            columnDefs: [
                {
                    headerName: 'Review Profile',
                    field: 'review',
                    cellRenderer: 'reviewUserRenderer',
                    cellStyle: { 'text-align': 'center' }, hide:true
                },
                {
                    headerName: 'Full Name',
                    field: 'fullName',
                    cellRenderer: 'userProfileRenderer'
                },
                {
                    headerName: 'Email',
                    field: 'email'
                },
                {
                    headerName: 'Company Name',
                    field: 'companyName'
                },
                { headerName: 'Sales Rep. Full Name', field: 'tdSalesRepFullName', hide:true },
                { headerName: 'Job Title', field: 'jobTitle' },
                { headerName: 'Vendor', field: 'vendorName', hide:true },
                {
                    headerName: 'Approval Date',
                    field: 'approvalDate',
                    cellRenderer: (approvalDate) => {
                        return approvalDate.value ? (new Date(approvalDate.value)).toLocaleDateString() : '';
                   }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    cellRenderer: 'activeUserRenderer'
                }
            ],
            frameworkComponents: {
                reviewUserRenderer: ReviewUserRendererComponent,
                activeUserRenderer: ActiveUserRendererComponent,
                userProfileRenderer: UserprofileRendererComponent
            }
        };

        return activeUserConfig;
    }
}
