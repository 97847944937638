import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  template: `<div class="d-flex justify-content-center mt-5">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>`,
  styles: []
})
export class LoginComponent implements OnInit {
  loginCode: string;
  state: string;
  payloadDetails = [];
  loginRole: string;
  loginAuth: string;
  returnUrl: string;
  constructor(private route: ActivatedRoute,
    private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.loginCode = this.route.snapshot.queryParamMap.get('code');
    this.state = this.route.snapshot.queryParamMap.get('state');
    this.returnUrl = localStorage.getItem('redirectUrl');
    if (this.loginCode && this.state) {
      this.authLogin(); 
    } else {
      this.router.navigate(['user/login']);
    }    
  }
  authLogin() {
    this.authService.removeToken();
    this.authService.postAuthLogin(this.loginCode, this.state).subscribe((data) => {
      if (data && data.identityToken) {
        this.authService.setToken(data.identityToken);
        if (data.refreshToken) {
          localStorage.setItem('refreshToken', data.refreshToken);
        }

        const token = localStorage.getItem('identityToken');
        const tokenPayload = decode(token);
        this.loginRole = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        this.loginAuth = tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision'];
        localStorage.setItem('vendorId', tokenPayload['vendor_id']);

        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
          localStorage.removeItem('redirectUrl');
        }
        else if (this.loginRole === 'customer' && this.loginAuth === 'Forbidden') {
          this.router.navigate(['user/registration']);
        } else if (this.loginRole === 'fishtankcustomer' && this.loginAuth === 'Authorized') {
          this.router.navigate(['user/dashboard']);
        } else if (this.loginRole === 'fishtankadmin' && this.loginAuth === 'Authorized') {
          this.router.navigate(['admin/lead-management']);
        } else if (this.loginRole === 'fishtankdeveloper' && this.loginAuth === 'Authorized') {
          window.location.href = "/dev/login";
        } else {
          this.router.navigate(['/']);
        }
      } else {
        this.router.navigate(['/']);
      }
    })
  }
}
