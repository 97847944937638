import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AgGridModule } from 'ag-grid-angular';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LinkRendererComponent } from './shared/components/grid-components/link-renderer.component';
import { TimeRendererComponent } from './shared/components/grid-components/time-renderer.component';
import { StatusRendererComponent } from './shared/components/grid-components/status-renderer.component';
import { ClaimLeadRendererComponent } from './shared/components/grid-components/claim-lead-renderer.component';
import { ActiveUserRendererComponent } from './shared/components/grid-components/active-user-renderer.component';
import { ReviewUserRendererComponent } from './shared/components/grid-components/review-user-renderer.component';
import { UserprofileRendererComponent } from './shared/components/grid-components/user-profile-renderer.component';
import { CustomElement } from './shared/models/custom-elements';
import { CustomElementService } from './shared/services/custom-element.service';
import { CampaignprofileRendererComponent } from './shared/components/grid-components/campaign-profile-renderer.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { RoleGuard } from './shared/services/role-guard.service'
import { AuthService } from './shared/services/auth.service';
import { ErrorHandlerService } from './shared/services/errorhandler.service';
import { TokenInterceptor } from './shared/services/http-interceptor';
import { LeadLinkRendererComponent } from './shared/components/grid-components/lead-link-renderer.components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureToggleService } from './shared/services/feature-toggle.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AgGridModule.withComponents([LinkRendererComponent, TimeRendererComponent, ClaimLeadRendererComponent,
      ReviewUserRendererComponent, ActiveUserRendererComponent, UserprofileRendererComponent, StatusRendererComponent,
      CampaignprofileRendererComponent, LeadLinkRendererComponent]),
    SharedModule.forRoot([]),
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [Title, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService, AuthService, RoleGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, { provide: ErrorHandler, useClass: ErrorHandlerService }, FeatureToggleService],
  // bootstrap: [AppComponent]
  entryComponents: [AppComponent]
})
export class AppModule {

  constructor(private customElementService: CustomElementService) {

  }

  ngDoBootstrap(app) {

    app.bootstrap(AppComponent);
    // this.registerCustomElements();
  }

  public registerCustomElements(): void {

    const customElements: CustomElement[] = [
      {
        element: 'app-root',
        component: AppComponent
      }
    ];

    this.customElementService.registerElements(customElements);
  }
}
