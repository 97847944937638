import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss']
})
export class CardTemplateComponent {

  @Input() cardTemplate: TemplateRef<HTMLElement>;
  @Input() card: any;

  constructor() {}
}