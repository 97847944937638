import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { FeatureToggleService } from '../../../shared/services/feature-toggle.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomePageComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = false;
  closeResult = '';
  itemValue;
  disabledContinue = true;
  customerOnboardingUrl;
  disableFishtankApp: boolean = false;
  constructor(config: NgbCarouselConfig, private modalService: NgbModal,private featureToggleService: FeatureToggleService) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true, backdrop: 'static', keyboard: false
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onItemChange(event) {
    if (event.target.name === 'register') {
      this.itemValue = this.customerOnboardingUrl;
    } else { this.itemValue = event.target.value; }
    this.disabledContinue = event.checked;
  }
  ngOnInit()
  {
    this.customerOnboardingUrl = environment.customerOnboardingUrl;
    this.disableFishtankApp = this.featureToggleService.disableFishtankApp();
  }

}
