import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserDashboardService } from '../../../user-dashboard/user-dashboard.service';

@Component({
    selector: 'claim-lead',
    template: `<div>
    <span *ngIf="userToClaim; else elseBlock"><a (click)="onClick($event)"><i class='fa fa-plus custom-plus pointer-link font-md text-primary'></i></a></span>
    <ng-template #elseBlock><span class="claim-dash"> - </span></ng-template>
    </div>`,
    styles: [`
    .custom-plus {
        font-size:16px;
        text-align: center;
        color: #00558C;
    }
    .claim-dash{
        height: 16px;
        width: 4px;
        color: #114C6F;
        font-family: Roboto;
        font-size: 14px;
    }
  `],
    providers: [UserDashboardService]
})
export class ClaimLeadRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public onCellClick = new EventEmitter<any>();
    userToClaim: boolean;
    userClaimId: string;

    constructor(private route: Router, private dashboardService: UserDashboardService) { }

    agInit(params: any): void {
        this.userClaimId = params.data.canUserClaim;
        if (this.userClaimId) {
            this.userToClaim = true;
        }
    }

    refresh(): boolean {
        return false;
    }

    onClick(event: any) {


        // this.dashboardService.claimedLead.next(this.params.data);
        // this.route.navigate(['/account-information', this.params.data.id]);
    }
}
