import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(value: string): string {
    if(!value)
    {
      return value;
    }
    let tempArray = value.split(",");
    let tempArrayNew=[];
    tempArray.forEach(function(data){
      tempArrayNew.push(data.split("-").pop());
    });
    return tempArrayNew.join(', ');
  }
}