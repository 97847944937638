import { createCustomElement, NgElement, WithProperties } from '@angular/elements';
import { Injectable, Injector } from '@angular/core';

import { CustomElement } from '../models/custom-elements';

@Injectable({
  providedIn: 'root'
})
export class CustomElementService {

  public allElements: CustomElement[] = [];

  constructor(private readonly injector: Injector) { }

  private registerElement(customElement: CustomElement): void {
    const element = createCustomElement(customElement.component,
      { injector: this.injector }
    );
    customElements.define(customElement.element, element as any);
  }

  public registerElements(customElements: CustomElement[]): void {

    customElements.forEach(x => this.registerElement(x));

    this.allElements = [...this.allElements, ...customElements];
  }
}
