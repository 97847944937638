import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpService } from '../../services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {

  constructor(private httpService: HttpService) {
    super();
  }

  getCalendarNotification() {
    return this.httpService.get('mydashboard/calendarNotification');
  }
}
