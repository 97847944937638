import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-redirect',
  template: `<div class="d-flex justify-content-center mt-5">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>`,
  styles: []
})
export class AuthRedirectComponent implements OnInit {
  commandFor: string;
  constructor(private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    this.commandFor = this.route.snapshot.routeConfig.path;
    this.authSettings();
  }

  authSettings() {
    this.authService.getAuthSettings().subscribe((data) => {
      if (this.commandFor === 'user/login') {
        this.authService.removeToken();
        window.location.href = data.UserLoginUrl;
      } else if (this.commandFor === 'admin/login') {
        this.authService.removeToken();
        window.location.href = data.AdminLoginUrl;
      } else if (this.commandFor === 'logout') {
        localStorage.removeItem('vendorId');
        localStorage.removeItem('identityToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('redirectUrl');
        // clear dev cookie and all tokens
        window.location.href = data.LogoutUrl;
      } else if (this.commandFor === 'context') {
        window.location.href = '/auth/context?token=' + localStorage.getItem('identityToken');
      }
    });
  }
}
