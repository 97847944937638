import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { map } from 'rxjs/operators';
import { HttpService } from '../shared/services/http.service';
import { CampaignDetailsViewmodel } from '../shared/models/campaign-details-viewmodel';
import { ProfileDetailsViewmodel } from '../shared/models/profiledetails-viewmodel';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AdminDashboardViewmodel } from '../shared/models/admin-dashboard-viewmodel';

@Injectable({
    providedIn: 'root',
})

export class AdminDashboardService extends BaseService {
    baseUrl: any;
    searchTextSource = new Subject<string>();
    searchTxt$ = this.searchTextSource.asObservable();
    downloadClaimData = new Subject();
    claimedData$ = this.downloadClaimData.asObservable();
    downloadUnclaimData = new Subject();
    unClaimedData$ = this.downloadUnclaimData.asObservable();
    updateCampaignList = new Subject();
    campaignUpdatedList$ = this.updateCampaignList.asObservable();

    searchTextForCampaignBackClick: string = '';
    campaignSortBy: string = 'id';
    campaignSortOrder: string = 'asc';

    userListDataForBackClick:AdminDashboardViewmodel;

    private history: string[] = []
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private httpService: HttpService,
                private router: Router, private location: Location) {
        super();
        this.baseUrl = baseUrl;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.history.push(event.urlAfterRedirects)
            }
        })
    }
    backToPreviousLink(): void {
        this.history.pop();
        if (this.history.length > 0) {
          this.location.back()
        } else {
          this.router.navigateByUrl("/admin/lead-management")
        }
    }
    downloadDataClaimed() {
        this.downloadClaimData.next();
    }
    downloadDataUnclaimed() {
        this.downloadUnclaimData.next();
    }
    sendData(text: string) {
        this.searchTextSource.next(text);
    }
    sendUpdateRequest() {
        this.updateCampaignList.next();
    }
    getNewUser(vendorId: number): Observable<any> {
      return this.httpService.get('adminusers/getNewUsers/?vendorId=' + vendorId)
    }

    getActiveUser(adminDashboardViewmodel, apiEndPoint): Observable<any> {
        return this.httpService.post(adminDashboardViewmodel, apiEndPoint);
    }

    getResellerCompanies() {
        return this.httpService.get('myadmindashboard/getResellerCompanies');
    }

    getVendors(getAll: boolean = false): Observable<any> {
        return this.httpService.get(`vendor/getVendors/${getAll}`);
    }

    createCampaign(formBody) {
        return this.httpService.post(formBody, 'myadmindashboard/addNewCampaign');
    }

    getCampaigns(unclaimedLeadsViewmodel): Observable<any> {
        return this.httpService.post(unclaimedLeadsViewmodel, 'myadmindashboard/getCampaigns');
    }

    getCampaignUnclaimedLeads(unclaimedLeadsDetailsViewModel): Observable<any> {
        return this.httpService.post(unclaimedLeadsDetailsViewModel, 'myadmindashboard/getCampaignUnclaimedLeads');
    }

    getclaimedLeadResellers(claimedLeadsViewmodel): Observable<any> {
        return this.httpService.post(claimedLeadsViewmodel, 'myadmindashboard/getClaimedCompanyAdminLeads');
    }

    getResellerClaimedLeads(claimedLeadsDetailsViewModel): Observable<any> {
        return this.httpService.post(claimedLeadsDetailsViewModel, 'myadmindashboard/getClaimedResellerAdminLeads');
    }

    getcampaignDetails(campaignCRMId: string): Observable<any> {
        return this.httpService.get(`myadmindashboard/getCampaignDetails/${campaignCRMId}`);
    }

    getResellerCompany(campaignDetailsViewmodel: CampaignDetailsViewmodel, apiEndPoint) {
        return this.httpService.postData(campaignDetailsViewmodel, apiEndPoint);
    }

    submitCampaignDetails(campaignDetails, apiEndPoint): Observable<any> {
        return this.httpService.postData(campaignDetails, apiEndPoint);
    }

    getadminDetails(profileDetailsViewmodel: ProfileDetailsViewmodel, apiEndPoint): Observable<any> {
        return this.httpService.postData(profileDetailsViewmodel, apiEndPoint);
    }

    getLeadManagementChartDetails(userId: string): Observable<any> {
        return this.httpService.get(`analytics/user/leadManagementChartDetails?userId=${userId}`);
    }

    getUserDetailsNotes(userId: string): Observable<any> {
        return this.httpService.get(`myadmindashboard/user/notes?userId=${userId}`);
    }

    getTotalRevenueChartDetails(userId: string): Observable<any> {
        return this.httpService.get(`analytics/user/totalRevenueChartDetails?userId=${userId}`);
    }

    getLeadDetails(leadId, userId = null): Observable<any> {
        return this.httpService.get(`myadmindashboard/getLeadDetails?leadId=${leadId}&userId=${userId}`);
    }

    getLeadFeedbacks(leadId, userId = null) {
        return this.httpService.get(`myadmindashboard/getLeadFeedbackDetails?leadId=${leadId}&userId=${userId}`);
    }

    getFeedbackQuestions(leadId, userId = null) {
        return this.httpService.get(`myadmindashboard/getFeedbackQuestions?leadId=${leadId}&userId=${userId}`);
    }

     addFeedback(formRequestViewmodel) {
      return this.httpService.postData(formRequestViewmodel, 'myadmindashboard/addFeedback');
     }

    getVendorCertificate(vendorCertificate): Observable<any> {
      return this.http.post<any>(`${this.baseUrl}api/adminusers/getVendorCertificate`,
            vendorCertificate, { responseType: 'blob' as 'json' }).pipe(map((data: any) => {
                return data;
            }));
    }

    submitAdminDetails(adminDetails, apiEndPoint): Observable<any> {
        return this.httpService.postData(adminDetails, apiEndPoint);
    }

    uploadLeadFile(file): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
                'accept': 'text/plain'
            }),
        };
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${this.baseUrl}api/myadmindashboard/uploadLeads`, formData, { reportProgress: true, observe: "events" });
    }

    downloadTemplate(): Observable<any> {
        let filename: string;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/vnd.ms-excel',
            }),
        };
        return this.http.get(`${this.baseUrl}api/myadmindashboard/downloadTemplate`, { responseType: 'blob' })
    }

    downloadUserDetails(profileDetailsViewmodel: any[], filename = 'data') {
        const csvData = this.ConvertToCSV(profileDetailsViewmodel, ['Company Name', 'First Name', 'Last Name', 'Email', 'Job Title',
            'Phone number', 'Location', 'Zip Code', 'Language', 'TD reseller ID', 'EC ID', 'FishTank approved', 'User approved date',
            'Vendor', 'Learner ID', 'Certification PDF uploaded', 'Product Lines', 'Sales Territory', 'Target Industry',
            'Target Company Size', 'Target Company Revenue']);
        // console.log(csvData)
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    ConvertToCSV(objArray, headerList) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

        for (const index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
                let head = headerList[index];
                let cellContent = array[i][head];
                if (cellContent) {
                  cellContent = cellContent.replace(/"/g, '""').replace(/\r\n/g, ' ').replace(/\t/g, ' ').replace(/\n/g, ' ');
                }
                
                line += '"' + cellContent + '",';
            }
            str += line + '\r\n';
        }
        return str;
  }

  downloadExcel(leadDetailsViewmodel: any[], filename = 'data') {
    const csvData = this.ConvertToCSV(leadDetailsViewmodel, ['status1', 'status2', 'Lead start date (MM-DD-YYYY)', 'Lead reseller user',
      'Lead reseller email', 'Lead ID', 'Campaign', 'Campaign CRM ID', 'Company name', 'City', 'State', 'Company size', 'Company revenue',
      'Product line', 'Industry', 'Primary contact', 'Contact email', 'Title', 'Website', 'Address', 'Phone1', 'Phone2', 'Account description',
      'Last feedback date', 'Call notes']);
    // console.log(csvData);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
    getTopResellers(campaignCRMId: string): Observable<any> {
        return this.httpService.get(`analytics/campaign/topResellers?campaignCRMId=${campaignCRMId}`);
    }

    getCampaignNotes (campaignCRMId: string): Observable<any> {
        return this.httpService.get(`myadmindashboard/campaign/notes?campaignCRMId=${campaignCRMId}`);
    }

    getUsersLeadClaimHistory(campaignCRMId: string, customerNumber: string): Observable<any> {
        return this.httpService.get(`analytics/campaign/account/usersLeadClaimHistory?campaignCRMId=${campaignCRMId}&customerNumber=${customerNumber}`);
    }

    topInactiveUsers(campaignCRMId: string): Observable<any> {
        return this.httpService.get(`analytics/campaign/topInactiveUsers?campaignCRMId=${campaignCRMId}`);
    }

    totalLeadOverview(campaignCRMId: string): Observable<any> {
        return this.httpService.get(`analytics/campaign/totalLeadOverview?campaignCRMId=${campaignCRMId}`);
    }
    
    leadsStatus(campaignCRMId: string): Observable<any> {
        return this.httpService.get(`analytics/campaign/leadsStatus?campaignCRMId=${campaignCRMId}`);
    }
}
