import { Injectable } from '@angular/core';
import { StatusRendererComponent } from '../components/grid-components/status-renderer.component';
import { CampaignprofileRendererComponent } from '../components/grid-components/campaign-profile-renderer.component';
import { LeadLinkRendererComponent } from '../components/grid-components/lead-link-renderer.components';

@Injectable({
  providedIn: 'root',
})
export class LeadsManagementConfiguration {

  public getUnclaimedLeadsTableConfigurations() {

    const unclaimedLeadsConfig = {
      columnDefs: [
        {
          headerTooltip: 'Lead ID',
          headerName: 'Lead ID',
          field: 'leadId',
          cellRenderer: 'leadLinkRendererComponent'
        },
        {
          headerName: 'Company Name',
          field: 'companyName',
          cellRenderer: (companyName) => {
            return "<span title='" + companyName.value + "'>" + companyName.value + "</span>";
          }
        },
        {
          headerName: 'City',
          field: 'city',
          cellRenderer: (city) => {
            return "<span title='" + city.value + "'>" + city.value + "</span>";
          }
        },
        { 
          headerName: 'State', 
          field: 'state',
          cellRenderer: (state) => {
            return "<span title='" + state.value + "'>" + state.value + "</span>";
          }
        },
        { 
          headerName: 'Company Size',
          field: 'companySize',
          cellRenderer: (companySize) => {
            return "<span title='" + companySize.value + "'>" + companySize.value + "</span>";
          } 
        },
        { 
          headerName: 'Company Revenue',
          field: 'companyRevenue',
          cellRenderer: (companyRevenue) => {
            return "<span title='" + companyRevenue.value + "'>" + companyRevenue.value + "</span>";
          }  
        },
        { 
          headerName: 'Product Line', 
          field: 'productLine',
          cellRenderer: (productLine) => {
            return "<span title='" + productLine.value + "'>" + productLine.value + "</span>";
          }  
        },
        { 
          headerName: 'Industry', 
          field: 'industry' ,
          cellRenderer: (industry) => {
            return "<span title='" + industry.value + "'>" + industry.value + "</span>";
          }  
        },
      ],
      frameworkComponents: {
        leadLinkRendererComponent: LeadLinkRendererComponent
      }
    };

    return unclaimedLeadsConfig;
  }

  public getClaimedLeadsTableConfigurations() {

    const claimedLeadsConfig = {
      columnDefs: [
        {
          headerTooltip: "Campaign ID",
          headerName: 'Campaign ID',
          field: 'campaignID',
          cellRenderer: 'campaignProfileRenderer'
        },
        {
          headerTooltip: "Campaign Name",
          headerName: 'Campaign Name',
          field: 'campaignName',
          cellRenderer: (campaginName) => {
            return "<span title='" + campaginName.value + "'>" + campaginName.value + "</span>";
          }
        },
        {
          headerTooltip: "Start Date",
          headerName: 'Start Date',
          field: 'startDate',
          hide: true,
          cellRenderer: (startDate) => {
            return startDate.value ? (new Date(startDate.value)).toLocaleDateString() : '';
          }
        },
        {
          headerTooltip: "End Date",
          headerName: 'End Date',
          field: 'endDate',
          hide: true,
          cellRenderer: (endDate) => {
            return endDate.value ? (new Date(endDate.value)).toLocaleDateString() : '';
          }
        },
        {
          headerTooltip: "Lead ID", headerName: 'Lead ID', field: 'leadID',
          cellRenderer: 'leadLinkRendererComponent'
        },
        { headerTooltip: "Company Name",
          headerName: 'Company Name',
          field: 'companyName',
          cellRenderer: (companyName) => {
            return "<span title='" + companyName.value + "'>" + companyName.value + "</span>";
          }
        },
        { headerTooltip: "City", 
          headerName: 'City', 
          field: 'city',
          cellRenderer: (city) => {
            return "<span title='" + city.value + "'>" + city.value + "</span>";
          } 
        },
        { headerTooltip: "State",
          headerName: 'State',
          field: 'state',
          cellRenderer: (state) => {
            return "<span title='" + state.value + "'>" + state.value + "</span>";
          }  
        },
        { headerTooltip: "Company Size",
          headerName: 'Company Size',
          field: 'companySize',
          cellRenderer: (companySize) => {
            return "<span title='" + companySize.value + "'>" + companySize.value + "</span>";
          }
        },
        { headerTooltip: "Company Revenue",
          headerName: 'Company Revenue', 
          field: 'companyRevenue',
          cellRenderer: (companyRevenue) => {
            return "<span title='" + companyRevenue.value + "'>" + companyRevenue.value + "</span>";
          }
        },
        { headerTooltip: "Product Line",
          headerName: 'Product Line',
          field: 'productLine',
          cellRenderer: (productLine) => {
            return "<span title='" + productLine.value + "'>" + productLine.value + "</span>";
          }
        },
        { headerTooltip: "Industry",
          headerName: 'Industry',
          field: 'industry',
          cellRenderer: (industry) => {
            return "<span title='" + industry.value + "'>" + industry.value + "</span>";
          }  
        },
        { headerTooltip: "Status" ,headerName: 'Status', field: 'status', cellRenderer: 'statusRenderer', minWidth: 100 },
      ],
      
      frameworkComponents: {
        
        statusRenderer: StatusRendererComponent,
        campaignProfileRenderer: CampaignprofileRendererComponent,
        leadLinkRendererComponent: LeadLinkRendererComponent

      }
    };

    return claimedLeadsConfig;
  }
}
