import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'cell-link-campaign',
    template: `<a class="grid-cell-link cursor-pointer text-primary pointer-link" title="{{params.value}}" (click)="onClick($event)"><u>{{ params.value }}</u></a>`,
    styles: [`
  `]
})
export class CampaignprofileRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public onCellClick = new EventEmitter<any>();

    constructor(private route: Router) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    onClick(event: any) {
        this.route.navigate(['admin/campaign-details', this.params.data.campaignID]);
    }
}
