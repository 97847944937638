import { Component, OnInit, NgZone, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDashboardService } from '../../../user-dashboard/user-dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormRequestViewmodel } from '../../../../app/shared/models/form-request-viewmodel';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { PlatformLocation } from '@angular/common';
import { AdminDashboardService } from '../../../admin-dashboard/admin-dashboard.service';

@Component({
  selector: 'app-lead-account-information',
  templateUrl: './lead-account-information.component.html',
  styleUrls: ['./lead-account-information.component.scss'],
  providers: [NgbAccordionConfig]
})
export class LeadAccountInformationComponent implements OnInit {

  companyId: number;
  accountData: any = [];
  callNotes: any = [];
  feedbackFormData = [];
  feedbackQuestions = [];
  requestBody: any = [];
  requestAddFeedback = {};
  feedbackDetailsData: any = {};
  bindFeedbackData: any = [];
  feedbackNumber: number;
  feebBackArray = [];
  leadId: number;
  userId;
  printContent: Boolean = true;
  isPrint = false;
  lastFeedbackDate;
  feedBackTitle;
  title;
  projectTimeFrameStart;
  projectTimeFrameEnd;
  monthDifferance;
  today;
  lastFeedbackDateExcel;
  canProvideFeedback = false;
  @ViewChild('noFeedback', { static: false }) noFeedback: TemplateRef<any>;
  modalMessage: string = 'The lead is closed. No more feedback is allowed.';
  isNoFeedback: boolean;
  currentLeadUserId;
  currentLeadVendorId;
  expectedRole;
  commonServiceToCall;
  returnToDashboard = 'user/dashboard';
  multiselectAnswers = [];
  selectedAnswers = [];
  selectedAnswersId = [];
  isInvalidateMultiselect: boolean = false;

  constructor(
    private route: Router,
    private userDashboardService: UserDashboardService, private modalService: NgbModal,
    private config: NgbAccordionConfig, private activatedRoute: ActivatedRoute, private location: PlatformLocation, private adminDashboardService: AdminDashboardService) {
    config.closeOthers = true;
    config.type = 'info';
    location.onPopState(() => this.modalService.dismissAll());
    this.expectedRole = this.activatedRoute.snapshot.data['expectedRole'];
    if (this.expectedRole === 'fishtankadmin') {
      this.commonServiceToCall = this.adminDashboardService;
      this.returnToDashboard = 'admin/lead-management/claimed'
    } else if (this.expectedRole === 'fishtankcustomer') {
      this.commonServiceToCall = this.userDashboardService;
    }
  }

  ngOnInit() {
    this.initPageInfo();
  }

  backLink() {
    this.adminDashboardService.backToPreviousLink();
  }

  initPageInfo() {
    this.leadId = this.activatedRoute.snapshot.params['id'];
    this.userId = this.activatedRoute.snapshot.params['userId'] || '';
    this.getLeadDetails(this.leadId, this.userId);
    this.getLeadFeedbacks(this.leadId, this.userId);
    this.getFeedbackQuestions(this.leadId, this.userId);

    // disable past date for datepicker
    const currentDate: Date = new Date();
    let dd: any = currentDate.getDate();
    let mm: any = currentDate.getMonth() + 1;
    let yyyy: any = currentDate.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    this.today = yyyy + '-' + mm + '-' + dd;
    //end//

  }

  getLeadDetails(leadId, userId = null) {
    this.commonServiceToCall.getLeadDetails(leadId, userId).subscribe((data: any) => {
      this.accountData = data;
      this.canProvideFeedback = this.accountData.canProvideFeedback;
      this.currentLeadUserId = this.accountData.userId;
      this.currentLeadVendorId = this.accountData.vendorId;
      // ProjectTimeFrame in range
      this.projectTimeFrameStart = new Date(this.accountData.projectTimeFrameStartDate);
      this.projectTimeFrameEnd = new Date(this.accountData.projectTimeFrameEndtDate);
      this.monthDifferance = this.monthDiff(this.projectTimeFrameStart, this.projectTimeFrameEnd);
    });

  }

  getLeadFeedbacks(leadId, userId = null) {
    this.commonServiceToCall.getLeadFeedbacks(leadId, userId).subscribe((data: any) => {
      this.feedbackDetailsData = data;
      this.feebBackArray = this.feedbackDetailsData.feedbacks || [];
      this.feebBackArray.length === 0 ? this.isNoFeedback = true : this.isNoFeedback = false;
      this.bindFeedbackData = this.feebBackArray;
      this.lastFeedbackDate = this.bindFeedbackData.map((date: any) => date.createdDatetime);
      this.feedBackTitle = this.bindFeedbackData.map((title: any) => title.feedbackTitle);
      this.title = this.feedBackTitle[0];
    });
  }

  monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  getFeedbackQuestions(leadId, userId = null) {
    this.commonServiceToCall.getFeedbackQuestions(leadId, userId).subscribe((data) => {
      this.feedbackFormData = data;
      data.forEach((questionDetails, index) => {
        if (questionDetails.answerType === 'multiselect') {
          this.multiselectAnswers = questionDetails.answer;
        }
      });
      this.multiselectAnswers.map((ele) => { ele.name = ele.answer; ele.code = ele.answerId; ele.checked });
    });
  }

  open(feedbackForm) {
    if (this.canProvideFeedback) {
      this.feedbackQuestions = [];
      this.feedbackQuestions.push(this.feedbackFormData[0]);
      this.modalService.open(feedbackForm, {
        centered: true, backdrop: 'static', windowClass: 'modal-width'
      });
    }
    else {
      this.modalService.dismissAll();
      this.modalService.open(this.noFeedback, { centered: true, backdrop: 'static', size: 'md' });
    }
  }

  openanswer(answedisplay) { this.modalService.open(answedisplay, { centered: true, backdrop: 'static' }); }

  onCloseMultiSelectAns(options) {
    this.selectedAnswers = this.prepareCheckedOptions(options, 'ans');
    this.selectedAnswersId = this.prepareCheckedOptions(options, 'id');
    if (this.selectedAnswers.length > 0) {
      this.isInvalidateMultiselect = false;
    } else {
      this.isInvalidateMultiselect = true;
    }
  }

  prepareCheckedOptions(options: Array<any>, flag) {
    let selectedOptions = options.filter((ele) => ele.checked).map((ele) => {
      if (ele.checked) {
        if (ele.id != null && ele.value != null) {
          return { value: ele.value, id: ele.id };
        }
        else {
          if (flag === 'ans') {
            return ele.code + '-' + ele.name;
          } else {
            return ele.code;
          }
        }
      }
    });
    return selectedOptions;
  }

  submitFeedbackForm(feedbackForm) {
    this.requestBody = [];
    this.feedbackQuestions.forEach((questionDetails, index) => {
      if (questionDetails.answerType === 'multiselect') {
        this.requestBody.push({
          questionId: questionDetails.questionId,
          question: questionDetails.question,
          //answerId: this.selectedAnswersId.join(),
          answerId: Math.min(...this.selectedAnswersId),
          answer: this.selectedAnswers.join(', ')
        });
      } else {
        this.requestBody.push({
          questionId: questionDetails.questionId,
          question: questionDetails.question,
          answerId: questionDetails.answerType === 'dropdown' ? questionDetails.answer[Number(feedbackForm.value[questionDetails.questionId]) - 1].answerId :
            questionDetails.answer[0].answerId,
          answer: questionDetails.answerType === 'dropdown' ? questionDetails.answer[Number(feedbackForm.value[questionDetails.questionId]) - 1].answer :
            String(feedbackForm.value[questionDetails.questionId])
        });
      }
    });
    this.modalService.dismissAll();
    this.sendFormData();
  }

  sendFormData() {
    const formRequestViewmodel = new FormRequestViewmodel();
    formRequestViewmodel.leadId = this.accountData.id;
    formRequestViewmodel.feedbackNumber = this.bindFeedbackData.length + 1;
    formRequestViewmodel.questionAnswers = this.requestBody;
    formRequestViewmodel.resellerContactName = this.accountData.partnerContact;
    formRequestViewmodel.leadContactId = this.accountData.leadContactId;
    formRequestViewmodel.leadCompanyName = this.accountData.companyName;
    if (this.expectedRole === 'fishtankadmin') {
      formRequestViewmodel.userId = this.currentLeadUserId;
      formRequestViewmodel.vendorId = this.currentLeadVendorId;
    }
    this.commonServiceToCall.addFeedback(formRequestViewmodel).subscribe((data: any) => {
      this.initPageInfo();
    });
  }

  fetchNextQuestion(event, currentQuestionNumber: number, currentQuestionInfo) {
    this.isInvalidateMultiselect = false;

    if (this.feedbackQuestions.length !== currentQuestionNumber) {
      this.feedbackQuestions = this.feedbackQuestions.slice(0, currentQuestionNumber);
    }
    const answerInfo = currentQuestionInfo.answer[event.target.type === 'date' || event.target.type === 'text' || event.target.type === 'checkbox' ? 0
      : (Number(event.target.value) - 1)];
    if (answerInfo) {
      const nextQuestion = this.feedbackFormData.find((questionInfo) => {
        return questionInfo.questionId === answerInfo.sequelQuestionId;
      });
      if (nextQuestion) {
        this.feedbackQuestions.push(nextQuestion);
        this.feedbackFormData.find((questionInfo) => {
          if (questionInfo.questionId === answerInfo.sequelQuestionId && questionInfo.answerType === 'multiselect') {
            this.isInvalidateMultiselect = true;
          }
        });
      }
    }
    // return false;
  }

  toggleQuestionPanel(event) {
    if (event.target.offsetParent.classList.contains('active')) {
      event.target.offsetParent.classList.remove('active');
      event.target.classList.remove('fa-sort-up');
      event.target.classList.add('fa-sort-down');
    } else {
      event.target.offsetParent.classList.add('active');
      event.target.classList.remove('fa-sort-down');
      event.target.classList.add('fa-sort-up');
    }
  }
  onDownloadExcel() {
    this.lastFeedbackDateExcel = new Date(this.lastFeedbackDate[0]).toLocaleDateString();
    const leadData = [
      {
        'status1': (this.accountData.status1 == null || this.accountData.status1 == '') ? 'NA' : this.accountData.status1,
        'status2': (this.accountData.currentStatus == null || this.accountData.currentStatus == '') ? 'NA' : this.accountData.currentStatus,
        'Lead start date (MM-DD-YYYY)': new Date(this.accountData.leadstartdate).toLocaleDateString(),
        'Lead reseller user': this.accountData.partnerContact,
        'Lead reseller email': this.accountData.contactEmail,
        'Lead ID': this.accountData.leadContactId,
        'Campaign': this.accountData.campaign,
        'Campaign CRM ID': this.accountData.campaignCRMID,
        'Company name': (this.accountData.companyName == null) ? 'NA' : this.accountData.companyName,
        'City': (this.accountData.city == null) ? 'NA' : this.accountData.city,
        'State': this.accountData.territory,
        'Company size': this.accountData.companySize,
        'Company revenue': this.accountData.companyRevenue,
        'Product line': this.accountData.productLine,
        'Industry': this.accountData.industry,
        'Primary contact': this.accountData.primaryContact,
        'Contact email': this.accountData.contactEmail,
        'Title': this.accountData.title,
        'Website': this.accountData.website,
        'Address': this.accountData.address,
        'Phone1': this.accountData.phone1,
        'Phone2': this.accountData.phone2,
        'Account description': this.accountData.accountDescription,
        'Last feedback date': (this.lastFeedbackDateExcel == null) ? 'NA' : this.lastFeedbackDateExcel,
        'Call notes': (this.accountData.callNotes === null || this.accountData.callNotes === '') ? 'NA' : this.accountData.callNotes

      }
    ];
    this.commonServiceToCall.downloadExcel(leadData, 'Fishtank_Lead-details');
  }

  onDownloadPDF() {
    // window.print();
    document.title = 'Fishtank_Lead-details';
    window.print();
    return false;
  }
}

