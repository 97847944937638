import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserDashboardService } from '../../../user-dashboard/user-dashboard.service';

@Component({
    selector: 'time-status',
    templateUrl: './time-renderer.component.html',
    styles: [],
    providers: [UserDashboardService]
})
export class TimeRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public myLeadsStatusData: any;
    public onCellClick = new EventEmitter<any>();
    successFeedback = 'Feedback Successfully Received';
    saleFeedback = 'Sale Of Product';
    recycleFeedback = 'Recycled';
    noFeedback = 'No Feedback Provided';
    registerFeedback = 'Registered Opportunity';
    clockFeedback = 'Continuing To Work On The Lead';
    bant = 'BANT Discrepancy';
    lostOpt = 'Lost Opportunity';
    wonRegistered = 'Won And Registered';
    nullFeedback = null;

    constructor(private route: Router, private dashboardService: UserDashboardService) { }

    agInit(params: any): void {
        this.params = params;
        this.myLeadsStatusData = params.data;
        // console.log(this.myLeadsStatusData.activeClass);

        // if (this.myLeadsStatusData.statusOne === this.successFeedback ||
        //     this.myLeadsStatusData.statusOne === this.successFeedback) {
        //     this.myLeadsStatusData.activeClass = 'tickmark';
        //     console.log("success");
        // }
    }

    refresh(): boolean {
        return false;
    }
}
