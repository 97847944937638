import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { UtilityService } from './shared/services/utility.service';
import { AuthService } from './shared/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'angular-fishtank';
  calendarPlugins = [dayGridPlugin]; // important!
  isRegistration = false;
  isHomepage = false;
  isNavbarVisible = false;
  subscriptions: Subscription[] = [];

  constructor(private route: Router, @Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute,
    private titleService: Title, private authService: AuthService, private utilityService: UtilityService) {
    this.routeEvent(this.route);
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.route
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });

    this.subscriptions.push(this.authService.getToken().subscribe(token => {
      if (token) {
        this.isNavbarVisible = true;
      }
    }));
  }
  routeEvent(router: Router) {

    router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {

        if (e.url.indexOf('/registration') !== -1) {

          this.isRegistration = true;
        } else {

          this.isRegistration = false;
        }

        if (e.url === '/' || e.url === '/landing-page') {

          this.isHomepage = true;
        } else {

          this.isHomepage = false;
        }
        if (e.url === '/profile') {
          this.document.body.classList.add('profile-page');
        } else {
          this.document.body.classList.remove('profile-page');
        }
      }
    });
  }

  ngOnDestroy() {
    this.utilityService.unsubscribeSubscriptions(this.subscriptions);
  }
}


