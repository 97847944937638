import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-status-legend',
    templateUrl: 'status-legend.component.html',
    styleUrls: ['status-legend.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StatusLegendComponent {

    @Output() hideLegend: EventEmitter<boolean> = new EventEmitter(false);

    constructor() { }

    hideComponent() {
        this.hideLegend.emit(true);
    }
}