import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { LinkRendererComponent } from '../components/grid-components/link-renderer.component';
import { TimeRendererComponent } from '../components/grid-components/time-renderer.component';
import { ClaimLeadRendererComponent } from '../components/grid-components/claim-lead-renderer.component';

@Injectable({
  providedIn: 'root',
})
export class DashboardConfigurations {
    constructor(@Inject(LOCALE_ID) private locale: string) {
    }

    public getMyLeadsTableConfigurations() {

        const myLeadsConfig = {
            columnDefs: [
                // {
                //     headerName: 'ID',
                //     field: 'id',
                //     hide: true
                // },
                {
                    headerName: 'Status1',
                    field: 'status1',
                    hide: true
                },
                {
                    headerName: 'Status2',
                    field: 'currentStatus',
                    hide: true
                },
                {
                    headerName: 'Lead start date (MM-DD-YYYY)',
                    field: 'leadStartDateExport',
                    hide: true
                },
                {
                    headerName: 'Lead reseller user',
                    field: 'partnerContact',
                    hide: true
                },
                {
                    headerName: 'Lead reseller email',
                    field: 'resellerEmail',
                    hide: true
                },
                {
                    headerName: 'Lead ID',
                    field: 'leadContactId',
                    hide: true
                },
                {
                    headerName: 'Primary contact',
                    field: 'primaryContact',
                    hide: true
                },
                {
                    headerName: 'Contact email',
                    field: 'contactEmail',
                    hide: true
                },
                {
                    headerName: 'Campaign CRM ID',
                    field: 'campaignCRMID',
                    hide: true
                },
                {
                    headerName: 'Title',
                    field: 'title',
                    hide: true
                },
                {
                    headerName: 'Website',
                    field: 'website',
                    hide: true
                },
                {
                    headerName: 'Address',
                    field: 'address',
                    hide: true
                },
                {
                    headerName: 'Phone1',
                    field: 'phone1',
                    hide: true
                },
                {
                    headerName: 'Phone2',
                    field: 'phone2',
                    hide: true
                },
                {
                    headerName: 'Account description',
                    field: 'accountDescription',
                    hide: true
                },
                {
                    headerName: 'Last feedback date',
                    field: 'lastFeedbackDate',
                    hide: true
                },
                {
                    headerName: 'Call notes',
                    field: 'callNotes',
                    hide: true
                },
                {
                    headerName: 'Company',
                    field: 'companyName',
                    cellRenderer: 'linkRenderer'
                },
                {
                    headerName: 'City',
                    field: 'city',
                },
                {
                    headerName: 'State',
                    field: 'territory',
            },
                { headerName: 'Company Size Id', field: 'companySizeId', hide: true},
                { headerName: 'Company Revenue Id', field: 'companyRevenueId', hide: true },
                { headerName: 'Company Size', field: 'companySize' },
                { headerName: 'Company Revenue', field: 'companyRevenue' },
                { headerName: 'Product Line', field: 'productLine' },
                { headerName: 'Industry', field: 'industry' },
                { headerName: 'Campaign', field: 'campaignCRMID' },
                {
                    headerName: 'Time Status',
                    field: 'timeStatus',
                    cellRenderer: 'timeRenderer'
                }
            ],
            frameworkComponents: {
                linkRenderer: LinkRendererComponent,
                timeRenderer: TimeRendererComponent
            }
        };

        return myLeadsConfig;
    }

    public getAvailableLeadsTableConfigurations() {

        const availableLeadsConfig = {
            columnDefs: [
                {
                    headerName: 'City',
                    field: 'city',
                },
                {
                    headerName: 'State',
                    field: 'territory',
                },
                { headerName: 'Company Size', field: 'companySize' },
                { headerName: 'Company Revenue', field: 'companyRevenue' },
                { headerName: 'Product Line', field: 'productLine' },
                { headerName: 'Industry', field: 'industry' },
                { headerName: 'Lead ID', field: 'leadContactId' },
                { headerName: 'Campaign', field: 'campaignCRMID' },
                {
                    headerName: 'Claim',
                    field: 'claim',
                    cellRenderer: 'claimLeadRenderer',
                    cellStyle: { 'text-align': 'center' },
                    width: 100
                }
            ],
            frameworkComponents: {
                claimLeadRenderer: ClaimLeadRendererComponent
            }
        };

        return availableLeadsConfig;
    }
}
