import { Component, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'ft-tooltip',
    templateUrl: 'tooltip.component.html'
})

export class TooltipComponent {

    @Input() message: string;

    ngOnInit() { }

}