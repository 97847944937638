import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileConfigurations {

  constructor() { }

  getProfileTabs() {

    const tabsConfiguration = [
        {
          caption: 'Account Information',
          name: 'accountInformation',
          index: 1
        },
        {
          caption: 'Login & Password',
          name: 'loginPassword',
          index: 2
        },
        {
          caption: 'Vendor & Parameters',
          name: 'vendorParameters',
          index: 3
        },
        {
          caption: 'Notifications',
          name: 'notifications',
          index: 4
        }
      ];

    return tabsConfiguration;
  }
}
