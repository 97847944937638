import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { AvailableLeadsViewnodel } from '../shared/models/available-leads-viewnodel';
import { ProfileButtonActions } from '../app.model';
import { HttpService } from '../shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class UserDashboardService extends BaseService {
    isActiveLeadsFlag:boolean=true;
    buttonActions: ProfileButtonActions;
    constructor(private httpService: HttpService) {
        super();
        this.buttonActions = new ProfileButtonActions();
    }

    getMyLeads(isActiveLeads: boolean = false): Observable<any> {
        return this.httpService.get(`mydashboard/myLeads/${isActiveLeads}`);
    }

    getAvailableLeads(availableLeadsViewnodel: AvailableLeadsViewnodel): Observable<any> {
        return this.httpService.postData(availableLeadsViewnodel, 'mydashboard/availableLeads');
    }

    claimLead(claimLeadRequest) {
      return this.httpService.post(claimLeadRequest, 'mydashboard/claimLead');
    }

    getResources(): Observable<any> {
        return this.httpService.get('resources/videos');
    }

    // For filter functionality
    getSalesTerritory(): Observable<any> {
      return this.httpService.get('registration/getSalesTerritories');
    }

    getCompanyRevenue(): Observable<any> {
      return this.httpService.get('registration/getTargetCompanyRevenue');
    }

    getCompanySize(): Observable<any> {
      return this.httpService.get('registration/getTargetCompanySize');
    }

    getIndustry(): Observable<any> {
      return this.httpService.get('registration/getTargetIndustries');
    }

    getNotifications(): Observable<any> {
        return this.httpService.get('UserDetails/getUserNotifications');
    }

    updateNotification(updateNotificationViewmodel): Observable<any> {
        return this.httpService.postData(updateNotificationViewmodel, 'UserDetails/updateSeenUserNotifications');
    }

    submitHelpFeedback(helpFeedback): Observable<any> {
        return this.httpService.postData(helpFeedback, 'help/submitHelpForm');
    }

    getHelpData(): Observable<any> {
        return this.httpService.get('registration/getUserBasicInfo')
    }

    getVendorsAndParameters(): Observable<any> {
        return this.httpService.get('UserDetails/getUserVendorsAndParameters');
    }

    submitVendorsAndParameters(parameters): Observable<any> {
        return this.httpService.postData(parameters, 'UserDetails/addUserVendorsAndParameters');
    }

    getaccountDetails(): Observable<any> {
        return this.httpService.get('UserDetails/getAccountSettings');
    }

    getNotificationSettings() {
        return this.httpService.get('UserDetails/getUserNotificationsSettings');
    }

    updateNotificationSettings(notificationViewmodel): Observable<any> {
        return this.httpService.postData(notificationViewmodel, 'UserDetails/updateUserNotificationsSettings');
    }

    getLeadDetails(leadId, userId = null): Observable<any> {
        return this.httpService.get(`mydashboard/getLeadDetails?leadId=${leadId}`);
    }

    getLeadFeedbacks(leadId, userId = null) {
        return this.httpService.get(`mydashboard/getLeadFeedbackDetails?leadId=${leadId}`);
    }

    getFeedbackQuestions(leadId, userId = null) {
        return this.httpService.get(`mydashboard/getFeedbackQuestions?leadId=${leadId}`);
    }

    addFeedback(formRequestViewmodel) {
        return this.httpService.postData(formRequestViewmodel, 'mydashboard/addFeedback');
    }

    handleError(arg0: string, productLines: any): (err: any, caught: Observable<any>) => import('rxjs').ObservableInput<any> {
        throw new Error('Method not implemented.');
    }

    downloadExcel(leadDetailsViewmodel: any[], filename = 'data') {
        const csvData = this.ConvertToCSV(leadDetailsViewmodel, ['status1', 'status2', 'Lead start date (MM-DD-YYYY)', 'Lead reseller user',
            'Lead reseller email', 'Lead ID', 'Campaign', 'Campaign CRM ID', 'Company name', 'City', 'State', 'Company size', 'Company revenue',
            'Product line', 'Industry', 'Primary contact', 'Contact email', 'Title', 'Website', 'Address', 'Phone1', 'Phone2', 'Account description',
            'Last feedback date', 'Call notes']);
        // console.log(csvData);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    ConvertToCSV(objArray, headerList) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

        for (const index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
                let head = headerList[index];
                let cellContent = array[i][head];
                if (cellContent) {
                  cellContent = cellContent.replace(/"/g, '""').replace(/\r\n/g, ' ').replace(/\t/g, ' ').replace(/\n/g, ' ');
                }

                line += '"' + cellContent + '",';
            }
            str += line + '\r\n';
        }
        return str;
    }

}
