import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import decode from 'jwt-decode';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;
  isUser: boolean;
  navbarItems;
  menuItems;
  fishtankUserName;
  homeOptionsName;
  homeOptionsLink;
  vendors: any[];
  fishtankUserOptions;
  subscriptions: Subscription[] = [];
  selectedVendor: any;
  isLegendVisible = false;
  selectedVendorName: string;
  accountStatus: number;
  loginRole: string;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(private route: Router, private navbarService: NavbarService) { }

  ngOnInit() {    
    if (window.location.pathname !== '/' && window.location.pathname !== "/context" && window.location.pathname !== "/logout" && window.location.pathname !== "/user/login" && window.location.pathname !== "/admin/login" && window.location.pathname !== "/dev/login") {      
      this.getNavbar();
      const token = localStorage.getItem('identityToken');
      const tokenPayload = decode(token);
      this.loginRole = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    }
  }

  getNavbar() {
    this.navbarService.getNavbarItems().subscribe((data: any) => {
      this.navbarItems = data;
      this.menuItems = this.navbarItems.menuOptions;
      this.fishtankUserName = this.navbarItems.fishtankUserName;
      this.homeOptionsName = this.navbarItems.homeOptions.name;
      this.homeOptionsLink = this.navbarItems.homeOptions.link;
      this.vendors = this.navbarItems.vendors;
      this.selectedVendor = this.vendors.find((vendor) => { return vendor.isDefault == true && vendor.accountStatus == 1 })
        || this.vendors.find((vendor) => { return vendor.vendorId == localStorage.getItem('vendorId') })
        || this.vendors.find((vendor) => { return vendor.accountStatus == 1 })
        || this.vendors[0];
      this.fishtankUserOptions = this.navbarItems.fishtankUserOptions;
    });
  }

  onCancel(event: boolean) {
    if (event === true) {
      this.isLegendVisible = false;
      this.selectedVendor = this.vendors.find((vendor) => { return vendor.vendorId == localStorage.getItem('vendorId') });
    }
  }

  onChangeVendor(event: any) {
    this.selectedVendorName = this.selectedVendor.vendorName;    
    this.accountStatus = this.selectedVendor.accountStatus;

    let isValidStatus = false;

    if (this.accountStatus == 1 || this.accountStatus == 5) {
      isValidStatus = true;
    }

    if (this.loginRole === 'fishtankcustomer' && !isValidStatus) {
      this.isLegendVisible = true;
      this.navbarService.byPassStep1 = true;
      localStorage.setItem('vendorIdForStep2', this.selectedVendor.vendorId);
      localStorage.setItem('vendorNameForStep2', this.selectedVendor.vendorName);
    } else {
      this.navbarService.updateVendorSelection(this.selectedVendor).pipe(take(1)).subscribe(() => { });
      localStorage.setItem('vendorId', this.selectedVendor.vendorId);
      this.goToUpdateData(this.route.url);
    }
  }

  goToUpdateData(routerLinkUrl) {
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([routerLinkUrl]);
    });
  }

}
