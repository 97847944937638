import { Injectable, Inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AuthService extends BaseService {
    baseUrl: any;
    sessionToken: BehaviorSubject<string> = new BehaviorSubject(null);
    refreshAuthToken: string;
    constructor(public jwtHelper: JwtHelperService, private http: HttpClient, @Inject('BASE_URL') baseUrl: string, public router: Router) {
        super();
        this.baseUrl = baseUrl;
    }
    getAuthSettings(): Observable<any> {
        return this.http.get(`${this.baseUrl}api/auth/settings`).pipe(map((data: any) => {
            return data ? data : [];
        }))
    }

    postAuthLogin(code, state): Observable<any> {
        return this.http.post(`${this.baseUrl}api/auth/login`, { code, state }).pipe(map((data: any) => {
            return data ? data : [];
        }));
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem('identityToken');
        // To check, token is expired and return true or false
        return !this.jwtHelper.isTokenExpired(token);
    }
    getToken() {
        this.sessionToken.pipe(take(1)).subscribe((token) => {
            if (!token) {
                this.sessionToken.next(localStorage.getItem('identityToken'));
            }
        })
        return this.sessionToken;
    }

    setToken(token: string) {
        this.sessionToken.next(token);
        localStorage.setItem('identityToken', token);
    }

    removeToken() {
        this.sessionToken.next(undefined);
        localStorage.removeItem('identityToken');
    }

    checkToken() {
        if (localStorage.getItem("identityToken") === null) {
            return true;
        }
    }
    getRefreshToken() {
        this.refreshAuthToken = localStorage.getItem('refreshToken');
        return this.refreshAuthToken;
    }
    refreshToken() {
        return this.http.post<any>(`${this.baseUrl}api/auth/refresh`,
            { 'accessToken': localStorage.getItem('identityToken'), 'refreshToken': this.getRefreshToken() }).pipe(tap((token) => {
                localStorage.removeItem('identityToken');
                localStorage.setItem('identityToken', token.identityToken);
                localStorage.setItem('refreshToken', token.refreshToken);
            }));
    }

}
