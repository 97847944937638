import { Component, Input, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, AfterViewChecked,
         ComponentFactoryResolver, ViewContainerRef, Renderer2 } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarService } from './calendar.service';
import { Router } from '@angular/router';
import { TooltipComponent } from '../tooltip/tooltip.component';
@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [CalendarService],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('fullcalendar', { static: false }) calendarComponent: FullCalendarComponent;


  calendarApi: Calendar;

  initialized = false;
  @Input() calendarId: String;
  calendarEvents: EventInput[] = [];
  calendarPlugins = [dayGridPlugin]; // important!
  isHidden = false;
  toolTipText;
  fcHeader = {
    left: 'prev',
    center: 'title',
    right: 'next'
  };
  componentRef: any;

  constructor(private route: Router, private calendarService: CalendarService,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef, private componentResolver: ComponentFactoryResolver) {}

  ngOnInit() { }
  ngAfterViewInit() { }

  ngAfterViewChecked() {
    this.calendarApi = this.calendarComponent.getApi();
    if (this.calendarApi && !this.initialized) {
      this.initialized = true;
      this.loadEvents();
    }
  }

  onEventRender(info: any) { }

  mouseOver(event) {
    let factory = this.componentResolver.resolveComponentFactory(TooltipComponent);
    this.componentRef = this.viewContainerRef.createComponent(factory);
    this.componentRef.instance.message = 'amount of leads that requires feedback';
    event.el.classList.add('ft-tooltip');
    this.renderer.appendChild(event.el, this.componentRef.location.nativeElement);
  }

  mouseOut(event) {
    this.renderer.removeChild(event.el, this.componentRef.location.nativeElement);
  }

  loadEvents() {
    this.calendarService.getCalendarNotification().subscribe((data) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            this.calendarEvents.push({
              'title': data[i].leadCount,
              'description': 'On hover message',
              'date': data[i].leadExpiryDate, 'backgroundColor': data[i].colorCode
            });
          }
          this.calendarApi.removeAllEventSources();
          this.calendarApi.addEventSource(this.calendarEvents);
        }
    });
  }
}



