import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from '../../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService extends BaseService {
  baseUrl: any;
  byPassStep1 = false;
  
  constructor(private httpService: HttpService) {
    super();
  }

  getNavbarItems(): Observable<any> {
    return this.httpService.postData(null, 'header/getheader');
  }

  updateVendorSelection(vendor) {
    return this.httpService.postData(vendor, 'header/updateVendorSelection');
  }
}
