import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    public user: any = {};
    // public _redirectURL = '/login.html';
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    vendorId;
    constructor(public authService: AuthService, public router: Router) { 
        this.setVendorId();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.authService.getToken().pipe(take(1)).subscribe((token) => {
            if (token) {
                request = this.addToken(request, token);
            }
        })
        // return next.handle(request);
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                if (!(localStorage.getItem('identityToken') && localStorage.getItem('refreshToken'))) {
                    this.router.navigate(['/']);
                } else { return this.handle401Error(request, next); }
            } else {
                return throwError(error);
            }
        }));
    }

    handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.identityToken);
                    return next.handle(this.addToken(request, token.identityToken)).pipe(catchError(error => {
                        if (error instanceof HttpErrorResponse && error.status === 401) {
                            return this.router.navigate(['/logout']);
                        }
                    }));
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request, token));
                }));
        }
    }

    addToken(request: HttpRequest<any>, token: string) {        
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
                'vendorId' : localStorage.getItem('vendorId')
            }
        });
    }

    setVendorId() {
        if (!(localStorage.getItem('vendorId'))) {
            localStorage.setItem("vendorId", "1");
        }
    }
}
