import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!

import { CalendarComponent } from './components/calendar/calendar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DashboardConfigurations } from './configurations/dashboard-config.service';
import { AdminLeadsConfigurations } from './configurations/admin-leads-config.service';
import { LeadsManagementConfiguration } from './configurations/leads-management-config.service';
import { TokenInterceptor } from './services/http-interceptor';
import { ProfileConfigurations } from './configurations/profile-config.service';
import { ContentComponent } from './components/content/content.component';
import { CardTemplateComponent } from './components/card-template/card-template.component';
import { LinkRendererComponent } from './components/grid-components/link-renderer.component';
import { TimeRendererComponent } from './components/grid-components/time-renderer.component';
import { StatusRendererComponent } from './components/grid-components/status-renderer.component';
import { ClaimLeadRendererComponent } from './components/grid-components/claim-lead-renderer.component';
import { ReviewUserRendererComponent } from './components/grid-components/review-user-renderer.component';
import { UserprofileRendererComponent } from './components/grid-components/user-profile-renderer.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { CustomElementService } from './services/custom-element.service';
import { ActiveUserRendererComponent } from './components/grid-components/active-user-renderer.component';
import { CampaignprofileRendererComponent } from './components/grid-components/campaign-profile-renderer.component';
import { LoginComponent } from './components/login/login.component';
import { AuthRedirectComponent } from './components/login/auth-redirect.component';
import { UtilityService } from './services/utility.service';
import { StatusLegendComponent } from '../../app/shared/components/status-legend/status-legend.component';
import { HomePageComponent } from '../../app/shared/components/home-page/home-page.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ErrorComponent } from '../../app/shared/components/error-page/error.component';
import { VendorPopupComponent } from './components/vendor-popup/vendor-popup.component';
import { LeadLinkRendererComponent } from './components/grid-components/lead-link-renderer.components';
import { LeadAccountInformationComponent } from './components/lead-account-information/lead-account-information.component';
import { ReplacePipe } from './pipes/replace-pipe';
@NgModule({
    declarations: [
        ContentComponent,
        CalendarComponent,
        NavbarComponent,
        CardTemplateComponent,
        LinkRendererComponent,
        TimeRendererComponent,
        ClaimLeadRendererComponent,
        ReviewUserRendererComponent,
        MultiSelectDropdownComponent,
        ActiveUserRendererComponent,
        UserprofileRendererComponent,
        StatusRendererComponent,
        CampaignprofileRendererComponent,
        LoginComponent,
        AuthRedirectComponent,
        StatusLegendComponent,
        HomePageComponent,
        TooltipComponent,
        ErrorComponent,
        VendorPopupComponent,
        LeadLinkRendererComponent,
        LeadAccountInformationComponent,
        ReplacePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        ContentComponent,
        CalendarComponent,
        NavbarComponent,
        CardTemplateComponent,
        LinkRendererComponent,
        ClaimLeadRendererComponent,
        MultiSelectDropdownComponent,
        ActiveUserRendererComponent,
        ReviewUserRendererComponent,
        UserprofileRendererComponent,
        CampaignprofileRendererComponent,
        StatusLegendComponent,
        HomePageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgbModule,
        FullCalendarModule
    ],
    // providers: [,
    //     DashboardConfigurations,
    //     ProfileConfigurations,
    //     { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    // ],
    bootstrap: [],
    entryComponents: [TooltipComponent]
})
export class SharedModule {

    static forRoot(items: any[]): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                DashboardConfigurations,
                AdminLeadsConfigurations,
                ProfileConfigurations,
                LeadsManagementConfiguration,
                CustomElementService,
                UtilityService,
            ]
        };
    }
}
