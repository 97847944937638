import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  payloadDetails = [];
  loginRole: string;
  loginAuth: string;
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // if (!this.auth.isAuthenticated()) {
    //   this.router.navigate(['/']);
    //   return false;
    // }
    if (this.auth.checkToken()) {
      localStorage.setItem('redirectUrl', state.url);
      let returnUrl = state.url.split('/')[1];
      if (returnUrl === 'user') {
        this.router.navigate(['user/login']);
      } else if (returnUrl === 'admin') {
        this.router.navigate(['admin/login']);
      }
      return false;
    }
    if (this.auth.isAuthenticated()) {
      // this.router.navigate([url]);
      let expectedRole = route.data.expectedRole.split(',');
      const authRole = route.data.authRole.split(',');
      const token = localStorage.getItem('identityToken');
      const tokenPayload = decode(token);
      this.loginRole = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      this.loginAuth = tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision'];

      if (!expectedRole.includes(this.loginRole)) {
        this.router.navigate(['error']);
        return false;
      } else if (!authRole.includes(this.loginAuth)) {
        this.router.navigate(['/']);
        return false;
      }
    }
    return true;
  }
}
