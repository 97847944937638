import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MultiSelectElement } from 'src/app/shared/models/multiselect-element';

@Component({
  selector: 'multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnChanges {

  @Input() selectOptions: Array<MultiSelectElement> = [];
  @Input() selectOptionsCompany: Array<MultiSelectElement> = [];
  @Input() selectOptionsCampaign: Array<MultiSelectElement> = [];
  @Input() campaignDetailSelectOptions: Array<MultiSelectElement> = [];
  @Input() multiSelectId: string;
  @Input() isError: boolean;
  @Input() isDisabled = false;
  @Input() isSearchFilter = false;
  @Input() isCampaignPage: boolean;
  @Input() isCampaignDetails: boolean;
  @Input() isCampaignDetailsOption: boolean;
  @Output() closeMultiSelect = new EventEmitter<any>();
  @Output() deleteReseller = new EventEmitter<any>();
  @Input() defaultSelection: String[] = [];

  isSelectAll = false;
  isAllCampaignSelected = false;
  selectedValueText = '';
  searchText = '';
  filteredOptions: Array<MultiSelectElement> = [];
  filteredCampany: Array<MultiSelectElement> = [];
  filteredOptionCamp;
  selectedOptions;
  selectedCompanyName;
  unselectedCompanyName;
  selectedCompanyValue;
  @Input() selectedValue = [];
  selectedCompany = [];
  finalSelection = [];
  selectedValueCompany;
  filteredOptionsCompany: Array<MultiSelectElement> = [];
  isPlaceholder: boolean = true;
  constructor() {
  }
  ngOnChanges() {
    this.onChangeCampaign();
    if (this.defaultSelection.length > 0) {
      this.defaultSelection.forEach(defaultOptionName => {
        this.selectOptions = this.selectOptions.map(option => {
          if (option.name === defaultOptionName) {
            option.checked = true;
          }
          return option;
        });
        this.onChange();
      });
    } else {
      this.selectOptions.forEach((ele) => ele.checked = false);

    }
    if (!this.selectOptionsCompany) {
      this.selectOptionsCompany = [];
    }
    this.selectOptionsCompany.forEach((company) => {
      if (company.isOptedIn === false) {
        company.checked = false;
      }
    });
    this.filteredOptions = this.selectOptions;
    this.filteredCampany = this.selectOptionsCompany;

    this.selectedCompanyName = this.selectOptionsCompany.filter((item) => item.isOptedIn === true);
    this.selectedCompany = this.selectedCompanyName.map((ele) => ele.name);
  }

  onChange(): void {
 
    const optionsChecked = this.selectOptions.map(({ checked }) => checked);

    this.selectedOptions = this.selectOptions.filter((item) => item.name && item.checked && item.code);
    this.selectOptionsCampaign = this.selectOptionsCompany.filter((item) => item.name && item.checked && (item.isOptedIn = true));

    this.selectedValueText = optionsChecked.filter((ele) => ele).length + ' selected';
    this.selectedValue = this.selectedOptions.map(ele => ele.name);
    if (this.selectedValue) { this.showPlaceholder(); }
    this.selectedCompany = this.selectOptionsCampaign.map(ele => ele.name);

    if (optionsChecked.every((opt) => opt)) {
      this.isSelectAll = true;
    } else {
      this.isSelectAll = false;
    }
  }

  // Code for Compaign options
  onChangeCampaign(): void {
    const optionsCampaignChecked = this.selectOptions.map((item) => item.name && item.isOptedIn && item.code);
    this.selectedValueText = optionsCampaignChecked.filter((ele) => ele).length + ' selected';
    if (optionsCampaignChecked.every((opt) => opt)) {
      this.isAllCampaignSelected = true;
    } else {
      this.isAllCampaignSelected = false;
    }
  }
  selectAllCampaignDetail(isAllCampaignSelect): void {
    if (isAllCampaignSelect) {
    this.selectOptions.forEach((element) => {
    element.isOptedIn = true;
    });
    this.selectedValueText = this.selectOptions.length + ' selected';
    } else {
    this.selectOptions.forEach((element) => {
    element.isOptedIn = false;
    });
    this.selectedValueText = '0 selected';
    }
    }

  showPlaceholder() {
    if (this.selectedValue.length === 0 && this.finalSelection.length === 0) {
      this.isPlaceholder = true;
    } else { this.isPlaceholder = false }
  }
  onSelectAllChange(isAllSelected): void {

    if (isAllSelected) {

      this.selectOptions.forEach((element) => {
        element.checked = true;
      });
      this.selectedValueText = this.selectOptions.length + ' selected';
    } else {
      this.selectOptions.forEach((element) => {
        element.checked = false;
      });
      this.selectedValueText = '';
    }
  }

  preventInsideClick(event: any): void {
    event.stopPropagation();
  }

  getSelectedValues(): void {
    this.closeMultiSelect.emit(this.selectOptions);
  }

  getSelectedCompanies(): void {
    this.closeMultiSelect.emit(this.selectOptionsCampaign);
  }

  removeCampaign(i) {
    const cutArr = this.selectedValue.splice(i, 1);
    this.selectedOptions.map(item => {
      if (item.name == cutArr[0]) {
        item.checked = false;
      }
    });
    this.finalSelection = this.selectedOptions.filter(item => item.checked === true);
    this.deleteReseller.emit(this.finalSelection);
    this.showPlaceholder();
  }

  removeCompany(i) {
    const cutArrList = this.selectedCompany.splice(i, 1);
    this.selectOptionsCampaign.map(item => {
      if (item.name == cutArrList[0]) {
        item.checked = false;
      }
    });
    this.finalSelection = this.selectOptionsCampaign.filter(item => item.isOptedIn === true);
    this.deleteReseller.emit(this.finalSelection);
  }

  onFilterTextChange(event) {

    if (event === '') {

      this.filteredOptions = this.selectOptions;
    } else if (event.length > 0) {

      this.filteredOptions = this.selectOptions.filter((element) => (
        (element.name).toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1));
    }
  }

  onCampaignFilter(event) {
    if (event === '') {
      this.filteredOptions = this.selectOptions;
    } else if (event.length) {
      this.filteredOptions = this.selectOptions.filter((element) =>
        ((element.name).toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1));
    }
  }

  onCampanyFilter(event) {
    if (event === '') {
      this.filteredCampany = this.selectOptionsCompany;
    } else if (event.length) {
      this.filteredCampany = this.selectOptionsCompany.filter((element) =>
        ((element.name).toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1));
    }
  }
}
