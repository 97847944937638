import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-error',
    template: `<div class="card">
    <div class="card-body">
  <div class="d-flex justify-content-center align-items-center card-height">
    <div class="text-center error-container"><i class="fa fa-exclamation-triangle font-icxl"></i>
    <p class="cell-link font-error mt-5 font-weight-bold">Oh no!</p>
    <p class="txt-color">Something went wrong. Don’t worry, though. All your leads and information are still safe.
      You can try again later.</p>
  </div>
</div>
</div>`,
    styles: [
        `.font-icxl { 
            font-size: 5.125rem;
            color: #006EA3;
        }
        .card-height {
             height: 87vh;
        }
        .txt-color {
            color: #007BAE;
            font-size: 1.5rem;
        }
        .font-error{
            font-size: 2.5rem;
        }
        .error-container{
            width: 580px;
        }`
    ]
})
export class ErrorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
